import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import SEO from '../../components/Seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO description="Using TypeScript with React hooks." title="Hooks" keywords={['hooks']} mdxType="SEO" />
    <h1 {...{
      "id": "hooks"
    }}>{`Hooks`}</h1>
    <h2 {...{
      "id": "usestate"
    }}>{`useState`}</h2>
    <p>{`Type inference works when state is initialized to a non-null value:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const [value, setValue] = useState('initial state');
`}</code></pre>
    <p>{`For non-null values:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const [value, setValue] = useState<string | null>('initial state');
`}</code></pre>
    <h2 {...{
      "id": "useeffect"
    }}>{`useEffect`}</h2>
    <p>{`Like `}<inlineCode parentName="p">{`useState`}</inlineCode>{`, type inference works for `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{`. Please note that you must return a function or `}<inlineCode parentName="p">{`undefined`}</inlineCode>{` for type inference to work correctly. As described in `}<a parentName="p" {...{
        "href": "https://levelup.gitconnected.com/@treyhuffine"
      }}>{`Trey Huffine`}</a>{`'s `}<a parentName="p" {...{
        "href": "https://levelup.gitconnected.com/usetypescript-a-complete-guide-to-react-hooks-and-typescript-db1858d1fb9c"
      }}>{`useTypescript — A Complete Guide to React Hooks and TypeScript`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// THIS IS INCORRECT | READ ABOVE AND DO NOT USE
function DelayedEffect(props: { timerMs: number }) {
  const { timerMs } = props;
  useEffect(
    // ** BAD! ** setTimeout implicitly returns a number because the arrow function body isn't wrapped in curly braces
    () =>
      setTimeout(() => {
        /* do stuff */
      }, timerMs),
    [timerMs]
  );
  return null;
}
`}</code></pre>
    <h2 {...{
      "id": "useref"
    }}>{`useRef`}</h2>
    <p>{`Like the two hooks above, type inference works for `}<inlineCode parentName="p">{`useRef`}</inlineCode>{`. When creating a ref container that does not have an initial value:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const ref = useRef<HTMLElement | null>(null);
`}</code></pre>
    <h2 {...{
      "id": "usereducer"
    }}>{`useReducer`}</h2>
    <p>{`The following `}<inlineCode parentName="p">{`useReducer`}</inlineCode>{` example is from the `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-reference.html#usereducer"
      }}>{`React docs`}</a>{` (with some added code to introduce complexity). Please note the use of `}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions"
      }}>{`discriminated unions`}</a>{` when defining `}<inlineCode parentName="p">{`Action`}</inlineCode>{`. This is necessary to define different `}<inlineCode parentName="p">{`payload`}</inlineCode>{` types for different `}<inlineCode parentName="p">{`type`}</inlineCode>{`s of actions.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`enum ActionType {
  Increment = 'increment',
  Decrement = 'decrement',
  ModifyLastButtonPressed = 'modify_last_button_pressed',
}

type AppState = {
  count: number;
  lastButtonPressed: string;
};

type Action =
  | { type: ActionType.Increment | ActionType.Decrement; payload: number }
  | { type: ActionType.ModifyLastButtonPressed; payload: string };

const initialState = { count: 0, lastButtonPressed: '' };

const reducer: React.Reducer<AppState, Action> = (state, action) => {
  switch (action.type) {
    case ActionType.Increment:
      return { ...state, count: state.count + action.payload };
    case ActionType.Decrement:
      return { ...state, count: state.count - action.payload };
    case ActionType.ModifyLastButtonPressed:
      return { ...state, lastButtonPressed: action.payload };
    default:
      throw new Error();
  }
};

function Counter({ initialState }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <React.Fragment>
      Count: {state.count} You last pressed: {state.lastButtonPressed}
      <br />
      <button
        onClick={() => {
          dispatch({ type: ActionType.Increment, payload: 1 });
          dispatch({ type: ActionType.ModifyLastButtonPressed, payload: '+' });
        }}
      >
        +
      </button>
      <button
        onClick={() => {
          dispatch({ type: ActionType.Decrement, payload: 1 });
          dispatch({ type: ActionType.ModifyLastButtonPressed, payload: '-' });
        }}
      >
        -
      </button>
    </React.Fragment>
  );
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      